<template>
  <div>
    <LoadingComponent />
  </div>
</template>

<script>
import LoadingComponent from '@/components/LoadingComponent/LoadingComponent';
export default {
    components: {
        LoadingComponent,
    },
    created() {
        // TODO: GM-495 add env variables. Waiting for devops
        window.location.assign(process.env.VUE_APP_VOICEROOM_URL);
    },
};
</script>